import { defineStore } from 'pinia'
import useUserStore from '~/store/user'
import { getI18nLanguageId } from '~/utils/languages'
import { PRIVATE_PAGES_ROUTES } from '~/constants/routes'

interface AppleLoginParams {
  Code: string
  FirstName: string
  JwtToken: string
  LastName: string
  Nonce: string
}

const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    errorMessage: '',
    showSuccessLoginMessage: false,
  }),
  actions: {
    async handleMeAfterLogin() {
      const userStore = useUserStore()
      await userStore.getUserMe()

      this.showSuccessLoginMessage = true

      navigateTo(PRIVATE_PAGES_ROUTES.PROFILE)
    },
    handleError(errorCode?: string) {
      const { translate } = useTranslations()
      this.errorMessage = translate('something-went-wrong', 'Something went wrong, please try again later')

      if (errorCode) {
        this.errorMessage = `(${errorCode}) ${message}`
      }

      console.error(message)
    },
    async login(loginParams) {
      const { email, password, hCaptchaToken, staySignedIn } = loginParams

      return useFetch('/api/auth/login/default', {
        method: 'POST',
        body: JSON.stringify({
          email,
          password,
          'h-captcha-response': hCaptchaToken,
          staySignedIn,
          languageId: getI18nLanguageId(),
        }),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      })
        .then((response) => {
          if (response?.error?.value) {
            this.showSuccessLoginMessage = false
            return false
          }

          this.handleMeAfterLogin()

          return true
        })
        .catch(() => {
          this.showSuccessLoginMessage = false

          return false
        })
    },
    async fetchGoogleLogin({ JwtToken }) {
      return useFetch('/api/auth/login/google', {
        method: 'POST',
        body: {
          JwtToken,
          languageId: getI18nLanguageId(),
        },
        credentials: 'include',
      })
        .then((response) => {
          console.log('response google fetch', response)
          this.handleMeAfterLogin()
          return true
        })
        .catch(() => false)
    },
    async fetchAppleLogin(params: AppleLoginParams) {
      const { Code, JwtToken, Nonce, FirstName, LastName } = params

      const { data, status, error } = await useFetch('/api/auth/login/apple', {
        method: 'POST',
        body: {
          Code,
          JwtToken,
          Nonce,
          languageId: getI18nLanguageId(),
          FirstName,
          LastName,
        },
        credentials: 'include',
      })

      if (error.value) {
        this.handleError(data.value.errorCode)
        return this.errorMessage
      }

      if (status === 200) {
        return await this.handleMeAfterLogin()
      }

      this.handleError(data.value.errorCode)
    },
    async fetchFacebookLogin({ accessToken }: { accessToken: string }) {
      try {
        await useFetch('/api/auth/login/facebook', {
          method: 'POST',
          body: {
            accessToken,
            languageId: getI18nLanguageId(),
          },
          credentials: 'include',
        })

        await this.handleMeAfterLogin()
        return true
      } catch {
        this.handleError()
      }
    },
    async resetPassword(resetCode: string, newPassword: string) {
      try {
        await useFetch('/api/auth/reset-password', {
          method: 'PUT',
          body: {
            code: resetCode,
            password: newPassword,
            languageId: getI18nLanguageId(),
          },
        })

        return true
      } catch {
        this.handleError()
      }
    },
    async logout() {
      try {
        await useFetch('/api/auth/logout', {
          method: 'GET',
          credentials: 'include',
        })

        const userStore = useUserStore()
        userStore.resetUser()
      } catch {
        return this.handleError()
      }
    },
    async forgotPassword({ email, hCaptchaToken }) {
      return await useFetch('/api/auth/forgot-password', {
        method: 'POST',
        body: {
          email,
          'h-captcha-response': hCaptchaToken,
          languageId: getI18nLanguageId(),
        },
      })
        .then(() => {
          return true
        })
        .catch((error) => {
          throw error
        })
    },
  },
})

export default useAuthStore
